import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Divider,
  ExitToAppIcon,
  HomeIcon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MuiDrawer
} from "../../mui";
import NavService from "../../services/NavService";
import { logoutUser } from "../../Store/auth-actions";
import ServiceIcon from "./ServiceList/ServiceIcon";
const drawerWidth = 225;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: 0,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: 0,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerWrapper = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Drawer = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const path = useSelector((state) => state.navigation.path);
  const { open, setOpen } = props;
  const [sideBarOptions, setSideBarOptions] = useState([]);
  const [adminView, setAdminView] = useState(false);

  const handleClose = (view) => {
    setOpen(false);
    props.handleExpanded();
  };

  useEffect(() => {
    if (path != "/home" && path.length) {
      NavService.getSidebar(path)
        .then((resp) => {
          setSideBarOptions(resp.data);
          return resp.data;
        })
        .then((data) => {
          NavService.checkAccess(data[0].service_id).then((resp) => {
            if (resp.data.role_name == "ADMIN") {
              setAdminView(true);
            } else {
              setAdminView(false);
            }
          });
        })
        .catch((err) => {});
    } else {
      setSideBarOptions([]);
      setAdminView(false);
    }
  }, [path]);

  console.log(sideBarOptions);

  return (
    isLoggedIn && (
      <DrawerWrapper variant="permanent" open={open}>
        <DrawerHeader style={{ border: "none" }}>
          <IconButton
            onClick={() => handleClose()}
            style={{ color: "rgba(0, 0, 0, 0.54)" }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <List>
          <Link
            to="/home"
            style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.54)" }}
          >
            <ListItem button key={"Home"}>
              <ListItemIcon
                style={{ color: "rgba(0, 0, 0, 0.54)", paddingLeft: "5px" }}
              >
                <HomeIcon></HomeIcon>
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItem>
          </Link>
          {sideBarOptions &&
            sideBarOptions.map((option) => (
              <Link
                key={option.subservice_name}
                to={option.subservice_route}
                style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.54)" }}
              >
                <ListItem button key={option.subservice_name}>
                  <ListItemIcon
                    style={{ color: "rgba(0, 0, 0, 0.54)", paddingLeft: "5px" }}
                  >
                    <ServiceIcon
                      iconName={option.icon}
                      color="rgba(0, 0, 0, 0.54)"
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.subservice_name} />
                </ListItem>
              </Link>
            ))}
        </List>
        <Divider />
        <List>
          <ListItem
            button
            key={"Logout"}
            style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.54)" }}
            onClick={() => dispatch(logoutUser())}
          >
            <ListItemIcon
              style={{ color: "rgba(0, 0, 0, 0.54)", paddingLeft: "5px" }}
            >
              <ExitToAppIcon></ExitToAppIcon>
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItem>
        </List>
      </DrawerWrapper>
    )
  );
};

export default Drawer;
