import { Auth } from "aws-amplify";
import axios from "axios";

const API_BASE_URL = "https://client-store.sampled-integration.com";

class StoreService {

  async advancedSearch(filterObj) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = filterObj;

    return await axios.post(
      API_BASE_URL + "/sample/advanced-file-search",
      body,
      myInit
    );
  }

  async uploadFile(file) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = file;

    return await axios.post(
      API_BASE_URL + "/sample/bulk-search/file/inventory-code",
      body,
      myInit
    );
  }

  async exportSearch(list) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = list;

    return await axios.post(
      API_BASE_URL + "/sample/export/inventory-code",
      body,
      myInit
    );
  }

  async bulkSearch(list) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = list;

    return await axios.post(
      API_BASE_URL + "/sample/bulk-search/inventory-code",
      body,
      myInit
    );
  }

  async getSingleCode(id) {
    let myInit = {
      params: {
        inventory_code: `${id}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      API_BASE_URL + "/sample/search/inventory-code",
      myInit
    );
  }

  async getSingleMoreData(id) {
    let myInit = {
      params: {
        inventory_code: `${id}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      API_BASE_URL + "/sample/detailed/inventory-code",
      myInit
    );
  }

  async getCodeList(id) {
    let myInit = {
      params: {
        query: `${id}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      API_BASE_URL + "/sample/list/inventory-code",
      myInit
    );
  }
}

export default new StoreService();
