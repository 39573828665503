import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation
} from "react-router-dom/cjs/react-router-dom.min";
import NavService from "../../../services/NavService";
import { servicesSearchActions } from "../../../Store/services-search";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.secondary.extralight,
  "&:hover": {
    backgroundColor: "rgb(211,211,211,0.9)",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.secondary.main,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "16ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const ServiceSearch = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [searchInput, setSearchInput] = useState("");
  const isSearching = useSelector((state) => state.servicesSearch.isSearching);

  const onInputHandler = (event) => {
    if (event.target.value.length && location.pathname != "/services/search") {
      history.push("/services/search");
    } else if (
      !event.target.value.length &&
      location.pathname == "/services/search"
    ) {
      history.goBack();
    }
    setSearchInput(event.target.value);
    NavService.getExportProfiles("ELI LILLY BSA", "d");
  };

  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(servicesSearchActions.setSearch({ query: searchInput })),
      250
    );
    return () => clearTimeout(timeOutId);
  }, [searchInput]);

  useEffect(() => {
    if (!isSearching) {
      setSearchInput("");
    }
    return;
  }, [isSearching]);

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon color="secondary" />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search Services…"
        inputProps={{ "aria-label": "search" }}
        onChange={onInputHandler}
        value={searchInput}
      />
    </Search>
  );
};

export default ServiceSearch;
