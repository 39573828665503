import { Amplify, Auth } from "aws-amplify";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import config from "../../aws-exports";
import { Box } from "../../mui";
import ParentAdmin from "../../pages/Parents/admin";
import ParentHome from "../../pages/Parents/home";
import ParentSamples from "../../pages/Parents/samples";
import ReqAccess from "../../pages/ReqAccess";
import ServicesSearchPage from "../../pages/ServicesSearchPage";
import SignIn from "../../pages/SignIn";
import { refreshUser } from "../../Store/auth-actions";
import Drawer from "../Navigation/Drawer";
import Header from "../Navigation/Header";
import PrivateRoute from "../PrivateRoute";
import CustomSnackbar from "../Snackbar";

Amplify.configure(config);

Auth.configure({
  oauth: {
    domain: "sampled.auth.us-east-1.amazoncognito.com",
    scope: ["email", "openid", "aws.cognito.signin.user.admin"],
    // redirectSignIn: "http://localhost:3000/home",
    // redirectSignOut: "http://localhost:3000/signin",
    redirectSignIn: "https://clients.sampled-biosphere.com/home",
    redirectSignOut: "https://clients.sampled-biosphere.com/signin",
    responseType: "code",
  },
});

const App = () => {
  const [open, setOpen] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const handleExpanded = (view) => {
    setIsExpanded((current) => !current);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshUser());
    return;
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Header
        setOpen={setOpen}
        open={open}
        handleExpanded={handleExpanded}
      />
      <Drawer
        setOpen={setOpen}
        open={open}
        handleExpanded={handleExpanded}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 10 }}>
        <Switch>
          <Route exact path="/request-access">
            <ReqAccess></ReqAccess>
          </Route>
          <PrivateRoute path="/home">
            <ParentHome />
          </PrivateRoute>
          <Route path="/signin" exact>
            {!isLoggedIn ? <SignIn /> : <Redirect to="/home" />}
          </Route>
          <PrivateRoute path="/store">
            <ParentSamples />
          </PrivateRoute>
          <PrivateRoute path="/admin">
            <ParentAdmin />
          </PrivateRoute>
          <PrivateRoute exact path="/services/search">
            <ServicesSearchPage />
          </PrivateRoute>
          <Route>
            <Redirect to="/home" />
          </Route>
        </Switch>
        <CustomSnackbar />
      </Box>
    </Box>
  );
};

export default App;
