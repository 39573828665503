import { Box, Button, Typography } from "../mui/";
import { Flex, Image, useTheme } from "@aws-amplify/ui-react";
import { useDispatch } from "react-redux";
import logo from "../assets/sampled-logo.png";
import { ParticlesContainer } from "../components/Background/ParticlesContainer";
import { authenticateUser } from "../Store/auth-actions";
import { Link } from "react-router-dom";

const SignIn = (props) => {
  const dispatch = useDispatch();

  const handleSignIn = () => {
    dispatch(authenticateUser());
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "90vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ParticlesContainer />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pb: 10,
        }}
      >
        <Box sx={{ zIndex: 1 }}>
          <img src={logo} alt="Logo" />
        </Box>
        <Button variant="contained" color="secondary" onClick={handleSignIn}>
          Sign In With Sampled
        </Button>
        <Box sx={{ zIndex: 1, paddingTop: '5px', cursor: "pointer" }}>
          <Link
            to="/request-access"
            style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.54)" }}
          >
            <Typography color="primary" >Request Access?</Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default SignIn;
