import { NavigateNextIcon, Box, Breadcrumbs, Link } from "../../mui";

import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const buildBreadCrumb = (path) => {
  const breadCrumbPaths = path.split("/");
  breadCrumbPaths.shift();
  let breadCrumbs = [];
  breadCrumbPaths.forEach((breadCrumb, index) => {
    breadCrumbs.push({
      path: breadCrumbPaths.slice(0, index + 1).join("/"),
      label: breadCrumb,
    });
  });
  return breadCrumbs;
};

const parsePath = (path) => {
  return path.replace(/\/(discover)\//, "");
};

const UIBreadcrumbs = (props) => {
  const location = useLocation();

  const queryPath = parsePath(location.pathname);

  const history = useHistory();

  const breadCrumbs = buildBreadCrumb(location.pathname);

  const handleBreadCrumbClick = (path) => {
    history.push(`/${path}`);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Breadcrumbs
        maxItems={5}
        separator={
          <NavigateNextIcon
            sx={{ color: "primary.main" }}
            fontSize="small"
            aria-label="breadcrumb"
          />
        }
        aria-label="breadcrumb"
      >
        {breadCrumbs.map((breadCrumb) => (
          <Link
            underline="hover"
            key={breadCrumb.path}
            color="secondary"
            sx={{
              fontSize: 18,
              cursor: "pointer",
            }}
            onClick={() => handleBreadCrumbClick(breadCrumb.path)}
          >
            {breadCrumb.label}
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default UIBreadcrumbs;
