import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import {
    Stack,
    Paper,
    Tooltip
} from "@mui/material";

import BiService from "../services/BiService";

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useDispatch } from "react-redux";
import { snackbarActions } from "../Store/snackbar";
import LockResetIcon from '@mui/icons-material/LockReset';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RefreshIcon from '@mui/icons-material/Refresh';

const ExportDownloads = (props) => {
    const [downloads, setSetDownloads] = React.useState([]);
    const dispatch = useDispatch();

    const handleDownload = (file) => {
        BiService.getFile(file)
            .then((resp) => {
                console.log(resp.data)
                window.location.replace(resp.data)
                // setSetDownloads(resp.data)
                dispatch(
                    snackbarActions.showNotification({
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: "Successful Download",
                    })
                );

            })
            .catch(err => {
                console.log(err)
                dispatch(
                    snackbarActions.showNotification({
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: "Failed Download",
                    })
                );
            })
    };
    const handleRefresh = (file) => {
        BiService.getDownloads(props.client, props.projects)
            .then((resp) => {
                console.log(resp)
                setSetDownloads(resp.data)

            })
            .catch(err => {
                console.log(err)
            })
    };

    useEffect(() => {
        BiService.getDownloads(props.client, props.projects)
            .then((resp) => {
                console.log(resp)
                setSetDownloads(resp.data)

            })
            .catch(err => {
                console.log(err)
            })

    }, []);

    return (
        <Box style={{ padding: "20px" }}>
            <Stack direction="row">
                <Typography
                    variant="h5"
                    color="secondary.main"
                    sx={{
                        marginBottom: '8px',

                    }}
                >
                    Export Downloads
                </Typography>
                <Tooltip
                    title="Refresh Downloads"
                    style={{ cursor: "pointer" }}
                    onClick={handleRefresh}

                >
                    <RefreshIcon color="primary" style={{ marginTop: "4px", marginLeft: '10px', cursor: "pointer" }}></RefreshIcon>
                </Tooltip>
            </Stack>

            <TableContainer component={Paper} variant="outlined">
                <Table size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Bucket</TableCell>
                            <TableCell align="center">Client</TableCell>
                            <TableCell align="center">Projects</TableCell>
                            <TableCell align="center">Created On</TableCell>
                            <TableCell align="center">File Name</TableCell>
                            <TableCell align="center">Status</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            downloads.map((row, index) => (

                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center" component="td" scope="row">
                                        {row.bucket || 'null'}
                                    </TableCell>
                                    <TableCell align="center" component="td" scope="row">
                                        {row.client || 'null'}
                                    </TableCell>
                                    <TableCell align="center" component="td" scope="row">
                                        {row.projects.join(',') || 'null'}
                                    </TableCell>
                                    <TableCell align="center" component="td" scope="row">
                                        {row.created_on || 'null'}
                                    </TableCell>
                                    <TableCell align="center" component="td" scope="row">
                                        {row.file_name || 'null'}
                                    </TableCell>

                                    <TableCell align="center"
                                    // onClick={(e) =>
                                    //     handleDetailData(row)
                                    // }
                                    >
                                        {
                                            row.ready_for_download ?
                                                <Tooltip
                                                    title="Ready to be Downloaded"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleDownload(row.file_name)}
                                                >
                                                    <SaveAltIcon color="green"></SaveAltIcon>
                                                </Tooltip>
                                                :
                                                <Tooltip
                                                    title="File Still Loading"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <LockResetIcon color="yellow"></LockResetIcon>
                                                </Tooltip>

                                        }

                                    </TableCell>
                                </TableRow>

                            ))
                        }

                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ExportDownloads;
