import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";
import UIWrapper from "../components/UIWrapper";
import {
  AttachFileIcon, Autocomplete,
  Box, Button, Checkbox,
  Chip,
  CircularProgress, Divider, FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem, Modal, Paper, ReadMoreIcon, Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination, TableRow, Tabs, TextField,
  Tooltip, Typography
} from "../mui";
import StoreService from "../services/StoreService";
import { snackbarActions } from "../Store/snackbar";
// import AdvancedSearch from "./storeComponents/AdvancedSearch";
import MoreDataCard from "./storeComponents/MoreDataCard";


const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

const tableRowData = [
  "Inventory Code",
  "Client",
  "Project",
  "Site",
  "Source",
  "Container Type",
  "Subject Code",
  "Date Received"
];

const BiTool = (props) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [value, setValue] = React.useState("one");
  const [searchType, setSearchType] = React.useState("bulk");
  // const [largeSearchList, setLargeSearchList] = React.useState([]);
  const [emptyHook, setEmptyHook] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);

  const [listLarge, setListLarge] = React.useState([]);
  const [largeInput, setLargeInput] = React.useState("");
  const [tableBulkData, setTableBulkData] = React.useState([]);
  const [pageLarge, setPageLarge] = React.useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = React.useState(6);
  const [val, setVal] = useState([]);
  const [filterExpand, setFilterExpand] = React.useState(false);
  const [inventoryCodeData, setInventoryCodeData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadData, setUploadData] = React.useState([]);
  const [filter, setFilter] = React.useState("Inv_Code");
  const [fileName, setFileName] = useState("");
  const [tableData, setTableData] = React.useState();

  const csvLink = useRef();

  const dispatch = useDispatch();

  const changeHandler = (event) => {
    if (event.target.files[0]) {
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: `Successfully Selected ${event.target.files[0].name}`,
        })
      );
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleClose = () => setOpen(false);

  const handleSubmission = (e) => {
    e.preventDefault();
    if (selectedFile) {
      const data = new FormData();
      data.append("file", selectedFile);

      StoreService.uploadFile(data)
        .then((resp) => {
          console.log(resp);
          setUploadData(resp.data);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: "Successfully Uploaded",
            })
          );
        })
        .catch((err) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: "Failed Request",
            })
          );
        });
      setSelectedFile(null);
      return;
    }
    setDataLoading(true);
    setPageLarge(0);
    setTableBulkData([]);
    setEmptyHook(emptyHook + 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setDataLoading(true);
    setPageLarge(0);
    setTableBulkData([]);
    setEmptyHook(emptyHook + 1);
  };

  const handleExport = async (event) => {
    let currentTimestamp = Date.now();
    let date = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(currentTimestamp);
    date = date.replace(/[^a-zA-Z0-9- ]/g, "_");
    date = date.replace(/\s/g, "");
    date = date.slice(0, -2);
    setFileName("sample_data_export_" + date + ".csv");
    let keyArray = tableBulkData.map(function (item) {
      return item["inventory_code"];
    });
    await StoreService.exportSearch(keyArray)
      .then((resp) => {
        setInventoryCodeData(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
    csvLink.current.link.click();
  };

  const handleDetailData = (code) => {
    StoreService.getSingleMoreData(code).then((resp) => {
      console.log(resp.data);
      let test = {}
      Object.keys(resp.data).forEach(ele  => {
        console.log(resp.data[ele])
        if(resp.data[ele] && resp.data[ele].length !== 0){
          test[ele] = resp.data[ele]
        }
      })
      
      if (test.length === 0 ) {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: 'No data imported from LIMS on these Samples',
          })
        );
        setTableData({});

      } else {
        setTableData(test);
      }
    });
  };

  const handleExpandView = () => {
    setFilterExpand(!filterExpand);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };

  useEffect(() => {
    StoreService.getCodeList(largeInput)
      .then((resp) => {
        setListLarge(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [largeInput]);

  useEffect(() => {
    if (val.length === 0) setDataLoading(false);
    StoreService.bulkSearch(val)
      .then((resp) => {
        console.log(resp.data)
        setTableBulkData(resp.data);
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDataLoading(false);
      });
  }, [emptyHook]);

  useEffect(() => {
    setTableBulkData(uploadData);
    setDataLoading(false);
  }, [uploadData]);

  useEffect(() => {
    console.log("effect is hit");
    setOpenDetail(true);
  }, [tableData]);

  return (
    <UIWrapper title="Sample Search" paper={false}>
      <Paper elevation={3} style={{ width: "95%", minHeight: "100%", marginTop: "20px", padding: "20px" }}>

        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="primary tabs example"
        >
          <Tab value="one" label="SEARCH" />
        </Tabs>
        <Divider style={{ width: "100%" }}></Divider>

        {value === "one" && (
          <Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: 25,
                gap: 20,
              }}
            >
              <Box>
                <FormControl
                  variant="standard"
                  sx={{ minWidth: 100, minHeight: 56, mb: 1 }}
                >
                  <InputLabel id="demo-simple-select-label"></InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filter}
                    label="Filter"
                    onChange={(event) => setFilter(event.target.value)}
                  >
                    <MenuItem value={"Inv_Code"}>Inv Code</MenuItem>
                    <MenuItem value={"OTHER"}>other..</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="standard"
                  style={{ width: "200px", marginBottom: "auto" }}
                >
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    freeSolo
                    inputValue={largeInput}
                    onInputChange={(e, newValue) => setLargeInput(newValue)}
                    options={listLarge}
                    onChange={(e, newValue) => setVal(newValue)}
                    // getOptionLabel={option => option.title}
                    value={val}
                    renderTags={() => { }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="standard-multiline-flexible"
                        variant="standard"
                        placeholder="Search.."
                        margin="normal"
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Typography sx={{ fontWeight: 35 }}>or</Typography>
              <Button
                variant="outlined"
                component="label"
                startIcon={<AttachFileIcon />}
              >
                {selectedFile ? selectedFile.name : "Upload a File"}
                <input
                  type="file"
                  name="file"
                  accept=".csv"
                  hidden
                  onChange={changeHandler}
                />
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmission}
              >
                Search
              </Button>

              {tableBulkData.length !== 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{}}
                    onClick={() => setOpen(true)}
                  >
                    Export
                  </Button>
                </Box>
              )}
            </Box>

            <Stack direction="row">
              {filterExpand
                ? val.map((option, index) => (
                  <Chip
                    variant="outlined"
                    onDelete={() => {
                      setVal(val.filter((entry) => entry !== option));
                    }}
                    size="small"
                    key={index}
                    label={option}
                  />
                ))
                : val.slice(0, 5).map((option, index) => (
                  <Chip
                    variant="outlined"
                    onDelete={() => {
                      setVal(val.filter((entry) => entry !== option));
                    }}
                    size="small"
                    key={index}
                    label={option}
                  />
                ))}

              {6 <= val.length && (
                <Chip
                  variant="outlined"
                  size="small"
                  label="Show More"
                  onClick={handleExpandView}
                />
              )}
            </Stack>

            {dataLoading === false && (
              <Box>
                {searchType === "bulk" && (
                  <TableContainer style={{ marginTop: "15px" }}>
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                      size={"small"}
                    >
                      <TableHead sx={{ minWidth: 650 }}>
                        <TableRow sx={{ minWidth: 650 }}>
                          {tableRowData.map((row, index) => (
                            <TableCell
                              align="left"
                              key={index}
                              style={{ fontWeight: "bold" }}
                            >
                              {row}
                            </TableCell>
                          ))}
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ border: "none" }}>
                        {tableBulkData.length !== undefined &&
                          tableBulkData
                            .slice(
                              pageLarge * rowsPerPageLarge,
                              pageLarge * rowsPerPageLarge + rowsPerPageLarge
                            )
                            .map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  {row.inventory_code || "null"}
                                </TableCell>
                                <TableCell align="left">
                                  {row.client || "null"}
                                </TableCell>
                                <TableCell align="left">
                                  {row.project || "null"}
                                </TableCell>
                                <TableCell align="left">{row.site || "null"}</TableCell>
                                <TableCell align="left">{row.source || "null"}</TableCell>
                                <TableCell align="left">
                                  {row.container_type || "null"}
                                </TableCell>
                                <TableCell align="left">{row.subject_code || "null"}</TableCell>
                                <TableCell align="left">{row.date_received || "null"}</TableCell>

                                <TableCell
                                  align="left"
                                  onClick={(e) =>
                                    handleDetailData(row.inventory_code)
                                  }
                                >
                                  <Tooltip
                                    title="More Detail"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <ReadMoreIcon></ReadMoreIcon>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[6, 10, 25]}
                      component="div"
                      count={tableBulkData.length}
                      rowsPerPage={rowsPerPageLarge}
                      page={pageLarge}
                      onPageChange={handleChangePageLarge}
                      onRowsPerPageChange={handleChangeRowsPerPageLarge}
                    />
                  </TableContainer>
                )}
              </Box>
            )}
            {dataLoading === true && (
              <Box>
                <CircularProgress
                  style={{ position: "absolute", top: "55%", left: "35%" }}
                  size={"11rem"}
                />
              </Box>
            )}
          </Box>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack sx={style}>
            <Typography
              variant="h1"
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "54px",
                lineHeight: "116.7%",
                letterSpacing: "-1.5px",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Export Tool
            </Typography>
            <Divider style={{ width: "100%" }}></Divider>
            {dataLoading === false && (
              <FormGroup style={{ padding: "30px" }}>
                <FormControlLabel control={<Checkbox />} label="Default" />
                <FormControlLabel control={<Checkbox />} label="sample" />
                <FormControlLabel control={<Checkbox />} label="metadata" />
                <FormControlLabel control={<Checkbox />} label="shipping" />
                <FormControlLabel control={<Checkbox />} label="storage" />
                <Box style={{ paddingTop: "30px" }}>
                  <Button variant="contained" onClick={handleExport}>
                    CONFIRM
                  </Button>
                </Box>
                <CSVLink
                  data={inventoryCodeData}
                  filename={fileName}
                  className="hidden"
                  ref={csvLink}
                  target="_blank"
                />
              </FormGroup>
            )}
          </Stack>
        </Modal>

        {(tableData && tableData.length !== 0) && (
          <MoreDataCard
            openDetail={openDetail}
            tableData={tableData}
            handleClose={() => setOpenDetail(false)}
          ></MoreDataCard>
        )}
      </Paper>
    </UIWrapper>
  );
};

export default BiTool;
