import { Auth } from "aws-amplify";
import axios from "axios";

const API_BASE_URL = "https://client-admin.sampled-integration.com";

class NavService {

  async getAllServices() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      API_BASE_URL + "/navigation/service/directory",
      myInit
    );
  }

  async searchServices(query) {
    let myInit = {
      params: {
        query,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      API_BASE_URL + "/navigation/service/search",
      myInit
    );
  }

  async getSidebar(route) {
    let myInit = {
      params: {
        route,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      API_BASE_URL + "/navigation/service/sidebar",
      myInit
    );
  }

  async checkAccess(service_id) {
    let myInit = {
      params: {
        service_id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      API_BASE_URL + "/navigation/service/access-level",
      myInit
    );
  }
}

export default new NavService();
