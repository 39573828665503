import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import mapManifestReducer from "./map-manifest";
import navigationReducer from "./navigation";
import servicesSearchReducer from "./services-search";
import snackbarReducer from "./snackbar";

const store = configureStore({
  reducer: {
      auth : authReducer,
      snackbar : snackbarReducer,
      mapManifest: mapManifestReducer,
      servicesSearch: servicesSearchReducer,
      navigation: navigationReducer
    }
});

export default store;