import React from "react";
import { Grid, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { PropTypes } from "prop-types";

const DocCard = (props) => {
  return (
    <>
      {props.files.map((row, index) => (
        <Grid item xs={4} key={index}>
          <FolderIcon
            style={{
              size: "extralarge",
              color: "#9b51e0",
              width: "1.5em",
              height: "1.5em",
              cursor: "pointer",
            }}
          ></FolderIcon>
          <Typography
            variant="body2"
            style={{ overflow: "hidden", color: "rgba(108, 117, 125, 0.5)" }}
          >
            {row.file_name ? row.file_name : "No File Name"}
          </Typography>
          <Typography
            variant="body2"
            style={{ overflow: "hidden", color: "rgba(108, 117, 125, 0.5)" }}
          >
            {row.size ? row.size : "No File Size"}
          </Typography>
        </Grid>
      ))}
    </>
  );
};

DocCard.propTypes = {
  files: PropTypes.array,
};

export default DocCard;
