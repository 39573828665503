import { Divider, Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ServiceIcon from "../components/Navigation/ServiceList/ServiceIcon";
import NavService from "../services/NavService";
import { servicesSearchActions } from "../Store/services-search";
import { snackbarActions } from "../Store/snackbar";

const ServiceSummary = ({ icon, color, name, description, route }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(servicesSearchActions.endSearch());
    history.push(route);
  };

  return (
    <Grid item xs={12} md={4} container sx={{ display: "flex" }}>
      <Box
        component={Paper}
        sx={{
          p: 2,
          "&:hover": { background: "rgb(10,10,10,0.05)", cursor: "pointer" },
          width: "100%",
        }}
        onClick={onClickHandler}
      >
        <Box sx={{ display: "flex" }}>
          <ServiceIcon iconName={icon} color={color} />
          <Typography sx={{ ml: 1 }}>{name}</Typography>
        </Box>
        <Divider sx={{ width: "100%", mt: 1, mb: 1 }} />
        <Typography>{description}</Typography>
      </Box>
    </Grid>
  );
};

const ServicesSearchPage = (props) => {
  const query = useSelector((state) => state.servicesSearch.query);
  const [searchResponse, setSearchResponse] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    NavService.searchServices(query)
      .then((resp) => {
        setSearchResponse(resp.data);
        console.log(resp.data);
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: `Failed Request: ${err}`,
          })
        );
      });
    return;
  }, [query]);

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <Box sx={{ width: "100%", pl: 2, pt: 2 }}>
          <Typography variant="h5">Services</Typography>
        </Box>
      </Grid>
      <Grid item container xs={12}>
        <Divider sx={{ width: "100%" }} />
      </Grid>
      {searchResponse &&
        searchResponse.services.map((service, index) => (
          <ServiceSummary
            key={index}
            icon={service.icon}
            name={service.service_name}
            description={service.description}
            color={service.color}
            route={service.route}
          />
        ))}
      <Grid item container xs={24}>
        <Box sx={{ width: "100%", pl: 2, pt: 2 }}>
          <Typography variant="h5">Subservices</Typography>
        </Box>
      </Grid>
      <Grid item container xs={12}>
        <Divider sx={{ width: "100%" }} />
      </Grid>
      {searchResponse &&
        searchResponse.sub_services.map((subservice, index) => (
          <ServiceSummary
            key={index}
            icon={subservice.icon}
            name={subservice.subservice_name}
            description={subservice.description}
            route={subservice.subservice_route}
          />
        ))}
    </Grid>
  );
};

export default ServicesSearchPage;
