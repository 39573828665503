import { createSlice } from "@reduxjs/toolkit";

const initialManfiestState = {
  mapping: {},
  drop: [],
  add: [],
  order: [],
  rules: [],
  selected: {},
  selectedCount: 0,
};

const mapManifestSlice = createSlice({
  name: "mapManifest",
  initialState: initialManfiestState,
  reducers: {
    init(state, action) {
      state.rules = [];
      state.add = [];
      state.drop = [];
      state.selectedCount = 0;
      const initial_map = {};
      const initial_selected = {};
      action.payload.forEach((obj, index) => (initial_map[obj] = obj));
      state.mapping = initial_map;
      state.order = action.payload.map((col, index) => ({
        name: col,
        id: `id-${index}`,
      }));
      action.payload.forEach((obj, index) => (initial_selected[obj] = false));
      state.selected = initial_selected;
    },
    shift(state, action) {
      const result = Array.from(state.order);
      const [removed] = result.splice(action.payload.startIndex, 1);
      result.splice(action.payload.endIndex, 0, removed);
      state.order = result;
    },
    map(state, action) {
      state.mapping[action.payload.column] = action.payload.new_name;
    },
    drop(state, action) {
      if (action.payload.checked) {
        state.drop.push(action.payload.column);
      } else {
        state.drop = state.drop.filter(
          (column) => column != action.payload.column
        );
      }
    },
    check(state, action) {
      state.selected[action.payload.column] = action.payload.checked;
      if (action.payload.checked) {
        state.selectedCount += 1;
      } else {
        state.selectedCount -= 1;
      }
    },
    initRule(state, action) {
      state.rules.push({
        id: Math.random().toString(36).substring(7),
        column: Object.entries(state.selected).filter(
          (obj, index) => obj[1]
        )[0][0],
        rule: {
          action: action.payload.action,
          condition: [],
          do: [],
        },
      });
    },
    deleteRule(state) {
      state.rules.pop();
    },
    addValueMap(state) {
      state.rules[state.rules.length - 1]["rule"]["do"].push(["", ""]);
    },
    deleteValueMap(state, action) {
      state.rules[state.rules.length - 1]["rule"]["do"].splice(
        action.payload,
        1
      );
    },
    keyMap(state, action) {
      state.rules[state.rules.length - 1]["rule"]["do"][
        action.payload.index
      ][0] = action.payload.value;
    },
    valueMap(state, action) {
      state.rules[state.rules.length - 1]["rule"]["do"][
        action.payload.index
      ][1] = action.payload.value;
    },
    addCondition(state, action) {
      state.rules[state.rules.length - 1]["rule"]["condition"].push([
        "",
        "",
        "",
      ]);
    },
    conditionColumnMap(state, action) {
      state.rules[state.rules.length - 1]["rule"]["condition"][
        action.payload.index
      ][0] = action.payload.column;
    },
    conditionConditionMap(state, action) {
      state.rules[state.rules.length - 1]["rule"]["condition"][
        action.payload.index
      ][1] = action.payload.condition;
    },
    conditionValueMap(state, action) {
      state.rules[state.rules.length - 1]["rule"]["condition"][
        action.payload.index
      ][2] = action.payload.value;
    },
    deleteCondition(state, action) {
      state.rules[state.rules.length - 1]["rule"]["condition"].splice(
        action.payload,
        1
      );
    },
    shiftRule(state, action) {
      const result = Array.from(state.rules);
      const [removed] = result.splice(action.payload.startIndex, 1);
      result.splice(action.payload.endIndex, 0, removed);
      state.rules = result;
    },
    addColumn(state, action) {
      state.add.push({
        name: action.payload.name,
        default: action.payload.default,
      });
      state.mapping[`${action.payload.name}`] = action.payload.name;
      state.order.push({
        name: action.payload.name,
        id: `id-${state.order.length}`,
      });
    },
    append(state, action) {
      state.rules[state.rules.length - 1]["rule"]["do"].push([
        action.payload.prepend,
        action.payload.append,
      ]);
    },
    join(state, action) {
      state.rules[state.rules.length - 1]["column"] = action.payload.origin;
      state.rules[state.rules.length - 1]["rule"]["do"] = {
        target: action.payload.destination,
        separator: action.payload.separator,
        startSlice: action.payload.startSlice,
        endSlice: action.payload.endSlice,
        regex: action.payload.regex,
      };
    },
    slice(state, action) {
      state.rules[state.rules.length - 1]["rule"]["do"] = {
        startSlice: action.payload.startSlice,
        endSlice: action.payload.endSlice,
        regex: action.payload.regex,
      };
    },
    switch(state) {
      state.selected = {};
      state.selectedCount = 0;
    },
  },
});

export const mapManifestActions = mapManifestSlice.actions;

export default mapManifestSlice.reducer;
