import { Box, Button, Typography, Paper, Stack, Divider, TextField } from "../mui";
import { Flex, Image, useTheme } from "@aws-amplify/ui-react";
import { useDispatch } from "react-redux";
import logo from "../assets/sampled-logo.png";
import { ParticlesContainer } from "../components/Background/ParticlesContainer";
import { authenticateUser } from "../Store/auth-actions";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, { useState, useEffect } from "react";
import { snackbarActions } from "../Store/snackbar";
import ReqService from "../services/ReqService";
import ReactPhoneInput from 'react-phone-input-material-ui';

const styles = theme => ({
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
});

const ReqAccess = (props) => {
  const [org, setOrg] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [supervisor, setSupervisor] = React.useState('');
  const [supervisorEmail, setSupervisorEmail] = React.useState('');
  const [reason, setReason] = React.useState('');

  const [phoneNumber, setPhoneNumber] = React.useState('');

  const dispatch = useDispatch();

  const handleChangePhone = (newValue) => {
    setPhoneNumber(newValue)
  }

  const handleSubmitForm = () => {
    // dispatch(authenticateUser());
    if (org === '' || email === '' || firstName === '' || lastName === '' || reason === ''|| phoneNumber === '') {
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: 'Required Field Empty',
        })
      );
    } else {
      let formData = {
        organization: org,
        email: email,
        first_name: firstName,
        last_name: lastName,
        reason: reason,
        supervisor: supervisor,
        supervisor_email: supervisorEmail,
        phone_number: phoneNumber
      }

      ReqService.postReqForm(formData)
        .then(resp => {
          console.log(resp.data)
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: 'Successful Request Submission',
            })
          );
        })
        .catch(err => {
          console.log(err)
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: 'Network Error',
            })
          );
        })
    }

  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "90vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ParticlesContainer />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pb: 10,
        }}
      >
        <Paper
          sx={{ zIndex: 1 }}
          style={{ width: "50%", minWidth: "600px", minHeight: "500px", borderRadius: "15px" }}
          elevation={20}

        >
          <Stack style={{ padding: "30px" }}>
            <Typography color="secondary" sx={{ fontSize: 26 }}>Request Submission?</Typography>
            <Divider style={{ width: "100%" }}></Divider>
            <Stack direction="row">
              <ChevronRightIcon
                fontSize="medium"
                style={{
                  color: "#9b51e0",
                  marginTop: "24px",

                }}
              ></ChevronRightIcon>
              <TextField
                required
                label={"Organization"}
                type="text"
                variant="standard"
                style={{
                  textAlign: "center",
                  color: "white",
                  margin: "10px",
                  width: "90%",
                }}
                size="small"
                value={org || ""}
                onChange={(e) => setOrg(e.target.value)}
              />
            </Stack>
            <Stack direction="row">
              <ChevronRightIcon
                fontSize="medium"
                style={{
                  color: "#9b51e0",
                  marginTop: "24px",

                }}
              ></ChevronRightIcon>
              <TextField
                required
                label={"Email"}
                type="text"
                variant="standard"
                style={{
                  textAlign: "center",
                  color: "white",
                  margin: "10px",
                  width: "90%",
                }}
                size="small"
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Stack>
            <Stack direction="row" style={{ width: "100%" }}>
              <Stack direction="row">
                <ChevronRightIcon
                  fontSize="medium"
                  style={{
                    color: "#9b51e0",
                    marginTop: "24px",

                  }}
                ></ChevronRightIcon>
                <TextField
                  required
                  label={"First Name"}
                  type="text"
                  variant="standard"
                  style={{
                    textAlign: "center",
                    color: "white",
                    margin: "10px",
                    width: "100%",
                  }}
                  size="small"
                  value={firstName || ""}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Stack>


              <Stack direction="row">
                <ChevronRightIcon
                  fontSize="medium"
                  style={{
                    color: "#9b51e0",
                    marginTop: "24px",

                  }}
                ></ChevronRightIcon>
                <TextField
                  required
                  label={"Last Name"}
                  type="text"
                  variant="standard"
                  style={{
                    textAlign: "center",
                    color: "white",
                    margin: "10px",
                    width: "100%",
                  }}
                  size="small"
                  value={lastName || ""}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Stack>
            </Stack>

            <Stack direction="row">
              <ChevronRightIcon
                fontSize="medium"
                style={{
                  color: "#9b51e0",
                  marginTop: "24px",

                }}
              ></ChevronRightIcon>
              <TextField
                label={"Supervisor"}
                type="text"
                variant="standard"
                style={{
                  textAlign: "center",
                  color: "white",
                  margin: "10px",
                  width: "90%",
                }}
                size="small"
                value={supervisor || ""}
                onChange={(e) => setSupervisor(e.target.value)}
              />
            </Stack>

            <Stack direction="row">
              <ChevronRightIcon
                fontSize="medium"
                style={{
                  color: "#9b51e0",
                  marginTop: "24px",

                }}
              ></ChevronRightIcon>
              <TextField
                label={"Supervisor Email"}
                type="text"
                variant="standard"
                style={{
                  textAlign: "center",
                  color: "white",
                  margin: "10px",
                  width: "90%",
                }}
                size="small"
                value={supervisorEmail || ""}
                onChange={(e) => setSupervisorEmail(e.target.value)}
              />
            </Stack>

            <Stack direction="row">
              <ChevronRightIcon
                fontSize="medium"
                style={{
                  color: "#9b51e0",
                  marginTop: "24px",

                }}
              ></ChevronRightIcon>
              <TextField
                required
                multiline
                label={"Reason"}
                type="text"
                variant="standard"
                style={{
                  textAlign: "center",
                  color: "white",
                  margin: "10px",
                  width: "90%",
                }}
                size="small"
                value={reason || ""}
                onChange={(e) => setReason(e.target.value)}
                maxRows={3}
                inputProps={{ maxLength: 200 }}

              />

            </Stack>
            {/* <MuiTelInput value={phoneNumber} onChange={handleChangePhone} /> */}
            <ReactPhoneInput
              value={phoneNumber}
              onChange={handleChangePhone} // passed function receives the phone value
              component={TextField}
              style={{margin:"10px"}}
            />
       
            <Button variant="contained" color="secondary" onClick={handleSubmitForm} style={{marginTop:'10px'}}>
              Submit Request
            </Button>






          </Stack>
        </Paper>

      </Box>
    </Box>
  );
};

export default ReqAccess;
