import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import ParentWrapper from "../../components/ParentWrapper";
import PrivateRoute from "../../components/PrivateRoute";
import UserAppManager from "../../../src/pages/UserAppManager";

const ParentAdmin = (props) => {
  let { path } = useRouteMatch();
  return (
    <ParentWrapper path={path}>
      <PrivateRoute exact path={`${path}/users`}>
        <UserAppManager service={`${path}`} />
      </PrivateRoute>
      <Route>
        <Redirect to={`${path}/usersApp`} />
        <UserAppManager />
      </Route>
    </ParentWrapper>
  );
};

export default ParentAdmin;
