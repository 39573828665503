import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Auth } from "aws-amplify";
import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useDebounce } from "use-debounce";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "../../mui";
import Service from "./Service";

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

const BASE_URL = "https://admin.sampled-integration.com";

const ServicesManager = (props) => {
  const [serviceQuery, setServiceQuery] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [tableBulkData, setTableBulkData] = useState([]);
  const [pageLarge, setPageLarge] = useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = useState(6);

  const debouncedFilter = useDebounce(serviceQuery, 333);
  const tableRowData = ["Full Name", "User Name", "Role"];

  const fetchServices = async () => {
    const response = await axios.get(
      `${BASE_URL}/search/service?query=${serviceQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );
    return response.data;
  };

  const { isLoading, isSuccess, error, data } = useQuery(
    ["services", debouncedFilter],
    () => fetchServices(debouncedFilter),
    { keepPreviousData: true, enabled: Boolean(debouncedFilter) }
  );

  //TODO: Update dynamic
  const services = [
    { label: "Admin" },
    { label: "APT Tools" },
    { label: "Samples" },
    { label: "Accession" },
  ];

  const filteredServices = [];

  if (props.service === "/admin") {
    filteredServices.push(services[0]);
  }
  if (props.service === "/apt") {
    filteredServices.push(services[1]);
  }
  if (props.service === "/samples") {
    filteredServices.push(services[2]);
  }
  if (props.service === "/accession") {
    filteredServices.push(services[3]);
  }

  return (
    <>
      <Autocomplete
        disablePortal
        id="service-select"
        options={props.service ? filteredServices : services}
        sx={{ width: 300 }}
        inputValue={inputValue}
        onInputChange={(_event, serviceInputValue) => {
          setInputValue(serviceInputValue);
          setServiceQuery(serviceInputValue);
          if (serviceInputValue === "") {
            setServiceQuery(false);
          }
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled
            label="Select Service"
            variant="outlined"
            required
          />
        )}
      />
      <TableContainer style={{ marginTop: "15px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size={"small"}>
          <TableHead sx={{ minWidth: 650 }}>
            <TableRow sx={{ minWidth: 650 }}>
              {tableRowData.map((row, index) => (
                <TableCell
                  align="left"
                  key={index}
                  style={{ fontWeight: "bold" }}
                >
                  {row}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ border: "none" }}>
            {isSuccess &&
              data.map((service) => (
                <>
                  <Service key={service.service_id} service={service} />
                </>
              ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[6, 10, 25]}
          component="div"
          count={tableBulkData.length}
          rowsPerPage={rowsPerPageLarge}
          page={pageLarge}
          onPageChange={(event, newPage) => {
            console.log(newPage);
            setPageLarge(newPage);
          }}
        />
      </TableContainer>
      {isLoading && <span>Loading..</span>}
      {error && <span>Error!</span>}
      {isSuccess && !data.length && null}
    </>
  );
};

export default ServicesManager;
