import AppsRoundedIcon from "@mui/icons-material/AppsRounded";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NavService from "../../../services/NavService";
import { snackbarActions } from "../../../Store/snackbar";
import ServiceIcon from "./ServiceIcon";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    // backgroundColor: theme.palette.secondary.main,
    // color: theme.palette.primary.main,
  },
}));

const ServiceList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [availableServices, setAvailableServices] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    NavService.getAllServices()
      .then((resp) => {
        setAvailableServices(resp.data);
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed Request",
          })
        );
      });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Button
          onClick={handleClick}
          startIcon={<AppsRoundedIcon />}
          color="secondary"
          size="large"
          sx={{
            "&:hover": {
              backgroundColor: "#fff",
              color: "primary.main",
            },
          }}
        >
          Services
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            bgcolor: "#fff",
            color: "black",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 30,
              width: 10,
              height: 10,
              bgcolor: "white",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        // transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ display: "flex", mr: 1, ml: 1 }}>
          <Box>
            <StyledMenuItem onClick={() => history.push("/about")}>
              Recently Viewed
            </StyledMenuItem>
            <StyledMenuItem>All Services</StyledMenuItem>
          </Box>
          <Divider
            orientation="vertical"
            sx={{ height: "auto", ml: 1, mr: 1 }}
          />
          <Box sx={{ minWidth: 200 }}>
            {availableServices.map((service) => (
              <StyledMenuItem
                key={service.service_id}
                onClick={() => history.push(service.route)}
              >
                <ListItemIcon>
                  <ServiceIcon iconName={service.icon} color={service.color} />
                </ListItemIcon>
                {service.service_name}
              </StyledMenuItem>
            ))}
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default ServiceList;
