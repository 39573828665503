import { createTheme } from "@mui/material";

let theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      // main: "#f5f5f5",
      // main: "#f2f3f4"
      main: "#9b51e0",
    },
    blue: { main: "#4ABCEC" },
    yellow: { main: "#F9B417" },
    purple: { main: "#662D91" },
    turquoise: { main: "#42C1C7" },
    red: { main: "#EE3263" },
    secondary: {
      main: "#484D56",
      extralight: "rgb(211,211,211,0.8)",
    },
    buttonDefault: {
      main: "#FFFFFF",
    },
    backdrop: {
      main: "#151515",
    },
    background: {
      main: "#9b51e0",
      paper: "#ffffff",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          // borderRadius: "10px",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Roboto"],
  },
});

export default theme;
