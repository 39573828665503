import { Auth } from "aws-amplify";
import axios from "axios";

const API_BASE_URL = "https://admin.sampled-integration.com";

class ReqService {

    async postReqForm(form) {
        let body = form
        let myInit = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        return await axios.post(
            API_BASE_URL + "/request-access",
            body,
            myInit
        );
    }

}

export default new ReqService();
