import { styled } from "@mui/material/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import logo from "../../assets/sampled-logo.png";
import {
  Box,
  Divider,
  IconButton,
  MenuIcon,
  MuiAppBar,
  Toolbar
} from "../../mui";
import ServiceList from "./ServiceList";
import ServiceSearch from "./ServiceSearch";

let settings = ["APT", "SNP", "PCR PORTAL", "PROJECT 3", "PROJECT 4"];

const drawerWidth = 225;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: 0,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
  }),
}));

const Header = (props) => {
  const { open, setOpen } = props;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const handleDrawerOpen = () => {
    setOpen(true);
    props.handleExpanded();
  };

  return (
    <AppBar
      position="fixed"
      elevation={1}
      open={open}
      sx={{ bgcolor: "white" }}
    >
      {isLoggedIn ? (
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{ flex: 1, height: 30, display: { xs: "none", md: "flex" } }}
          >
            <img src={logo} alt="Logo" />
          </Box>
          <ServiceList />
          <Divider orientation="vertical" sx={{ ml: 1, mr: 2, height: 30 }} />
          <ServiceSearch />
        </Toolbar>
      ) : (
        <Toolbar>
          <Box
            sx={{ flex: 1, height: 30, display: { xs: "none", md: "flex" } }}
          >
            <img src={logo} alt="Logo" />
          </Box>
        </Toolbar>
      )}
    </AppBar>
  );
};

export default Header;
