import { Alert, Slide, Snackbar } from "../../mui";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { snackbarActions } from "../../Store/snackbar";

const CustomSnackbar = (props) => {
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state) => state.snackbar.snackbarOpen);
  const snackbarType = useSelector((state) => state.snackbar.snackbarType);
  const snackbarMessage = useSelector(
    (state) => state.snackbar.snackbarMessage
  );
  function SlideTransition(props) {
    return <Slide {...props} direction="left" timeout={500} />;
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      snackbarActions.showNotification({
        snackbarOpen: false,
        snackbarType: snackbarType,
        snackbarMessage: snackbarMessage,
      })
    );
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      TransitionComponent={SlideTransition}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        color={snackbarType}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
