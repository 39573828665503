import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  isLoggedIn: localStorage.getItem("user") ? true : false,
  user: localStorage.getItem("user"),
};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      localStorage.setItem("user", action.payload);
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logout(state) {
      localStorage.removeItem("user");
      state.user = {};
      state.isLoggedIn = false;
    },
    update(state, action) {
      state.user = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
