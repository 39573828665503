import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ApiIcon from "@mui/icons-material/Api";
import ArchitectureOutlinedIcon from "@mui/icons-material/ArchitectureOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import KitchenIcon from "@mui/icons-material/Kitchen";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import TableChartIcon from "@mui/icons-material/TableChart";

const Icons = {
  AdminPanelSettingsIcon: AdminPanelSettingsIcon,
  HandymanOutlinedIcon: HandymanOutlinedIcon,
  DocumentScannerOutlinedIcon: DocumentScannerOutlinedIcon,
  KitchenIcon: KitchenIcon,
  SupervisedUserCircleIcon: SupervisedUserCircleIcon,
  ManageSearchIcon: ManageSearchIcon,
  FileUploadOutlinedIcon: FileUploadOutlinedIcon,
  ArchitectureOutlinedIcon: ArchitectureOutlinedIcon,
  RocketLaunchIcon: RocketLaunchIcon,
  DynamicFormIcon: DynamicFormIcon,
  FileDownloadOutlinedIcon: FileDownloadOutlinedIcon,
  TableChartIcon: TableChartIcon,
};

const ServiceIcon = ({ iconName, color }) => {
  const IconComponent = Icons[iconName];

  if (!IconComponent) {
    return <ApiIcon />;
  }

  return <IconComponent sx={{ color: color ? color : "black" }} />;
};

export default ServiceIcon;
