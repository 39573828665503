import { Divider, Typography, Box } from "../mui";
import React from "react";

const styles = {
  h1: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "64px",
    lineHeight: "116.7%",
    letterSpacing: "-1.5px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  h3: {
    fontFamily: "Helvetica",
    fontWeight: 300,
    fontSize: "3rem",
    lineHeight: 1.167,
    letterSpacing: "-0.01562em",
  },
};

const Home = (props) => {
  return (
    <Box
      style={
        props.isExpanded ? styles.mainIsNotExapanded : styles.mainIsExpanded
      }
    >
      <Typography variant="h1" style={styles.h1}>
        Welcome to Biosphere
      </Typography>
      <Divider></Divider>
    </Box>
  );
};

export default Home;
