import {
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  Autocomplete,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Box,
} from "../../mui";
import { Auth } from "aws-amplify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import axios from "axios";

import { useQuery, useMutation, useQueryClient } from "react-query";

import { useDebounce } from "use-debounce";
import { current } from "@reduxjs/toolkit";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import ButtonGroup from "@mui/material/ButtonGroup";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
const BASE_URL = "https://admin.sampled-integration.com";

const Service = ({ service }) => {
  const [targetUserToUpdate, setTargetUserToUpdate] = useState("");
  const [anchorEl, setAnchorEl] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [open, setOpen] = useState(false);
  const openTrue = Boolean(anchorEl);

  const [userQuery, setUserQuery] = useState("");
  const debouncedFilterUsersService = useDebounce(userQuery, 500);

  const handClickUserInfo = (event) => {
    setAnchorEl(event.currentTarget);
    setTargetUserToUpdate(event.currentTarget.firstElementChild.innerText);
  };

  const handleCloseTrue = (event) => {
    setAnchorEl(null);
    // console.log(event.target.innerText);
  };

  const fetchUserServices = async () => {
    const response = await axios.get(
      `${BASE_URL}/search/service/user?query=${userQuery}&service_id=${service.service_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );
    return response.data;
  };

  const fetchUsersNotServices = async () => {
    const response = await axios.get(
      `${BASE_URL}/search/service/user/add-user?query=${userQuery}&service_id=${service.service_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );
    // console.log(response.data);
    return response.data;
  };

  // ****************************************************************************************

  // Add user to service
  const addUser = async ({ user }) => {
    // console.log("addUser", user.username, service.service_id);
    const response = await axios.post(
      `${BASE_URL}/service/user/grant-access`,
      {
        username: user.username,
        service_id: service.service_id,
        role_name: "VIEW",
      },

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );
    // console.log(response.data);
    return response.data;
  };

  const addUserToService = () => {
    const queryClient = new useQueryClient();
    return useMutation(addUser, {
      onSuccess: (data) => {
        queryClient.invalidateQueries("usersServices");
      },
    });
  };
  const { mutate: mutateUser } = addUserToService();

  const handleUserAdd = (user) => {
    isSuccessNotServices &&
      usersNotServices.map((user) => {
        if (user.full_name === inputValue) {
          mutateUser({ user });

          isSuccessNotServices &&
            usersAvailableToAdd.map((user) => {
              if (user.label === inputValue) {
                // console.log(user.label);
              }
            });
        }
      });
    setOpen(false);
  };

  // Update user to service
  const upDateRole = async ({ user }) => {
    // console.log(
    //   "upDateRole",
    //   user.username,
    //   service.service_id,
    //   user.role_name
    // );
    const response = await axios.post(
      `${BASE_URL}/service/user/update-role`,
      {
        username: user.username,
        service_id: service.service_id,
        role_name: user.role_name,
      },

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );

    return response.data;
  };
  const updateUserToService = () => {
    const queryClient = new useQueryClient();
    return useMutation(upDateRole, {
      onSuccess: (data) => {
        // console.log("updateUserToService", data);
        queryClient.invalidateQueries("usersServices");

        // console.log("oldData", data);
      },
    });
  };
  const { mutate: mutateUpdateRole } = updateUserToService();

  const handleUserUpdate = (event) => {
    setAnchorEl(null);
    // document.getElementById("role_name").innerText = event.target.innerText;

    // console.log(targetUserToUpdate);
    mutateUpdateRole({
      user: {
        username: targetUserToUpdate,
        role_name: event.target.innerText,
      },
    });
  };

  // Remove user from service
  const removeUser = async ({ user }) => {
    // console.log("removeUser", user.username, service.service_id);
    const response = await axios.post(
      `${BASE_URL}/service/user/remove-access`,
      {
        username: user.username,
        service_id: service.service_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );
    return response.data;
  };
  const removeUserFromService = () => {
    const queryClient = new useQueryClient();
    return useMutation(removeUser, {
      onSuccess: (data) => {
        // console.log("updateUserToService", data);
        queryClient.invalidateQueries("usersServices");

        // console.log("oldData", data);
      },
    });
  };
  const { mutate: mutateRemoveUser } = removeUserFromService();

  const handleUserRemove = (event) => {
    const userToRemove = event.target.innerText;
    mutateRemoveUser({
      user: {
        username: userToRemove,
        service_id: service.service_id,
      },
    });
  };

  // ****************************************************************************************

  // Fetch users not in service
  const {
    isLoading: isLoadingNotServices,
    error: errorNotServices,
    data: usersNotServices,
    isSuccess: isSuccessNotServices,
    status: statusNotServices,
  } = useQuery(
    ["userNotServices", debouncedFilterUsersService],
    () => fetchUsersNotServices(debouncedFilterUsersService),
    {
      enabled: Boolean(debouncedFilterUsersService),
    }
  );

  // Fetch users in service
  const { isLoading, error, data, isSuccess } = useQuery(
    ["usersServices", debouncedFilterUsersService],
    () => fetchUserServices(debouncedFilterUsersService),
    {
      enabled: Boolean(debouncedFilterUsersService),
    }
  );

  const handleChange = (e) => {
    setUserQuery(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log("Trigger Lambda Here");
    setOpen(false);
  };

  const usersAvailableToAdd =
    isSuccessNotServices &&
    usersNotServices.map((user) => {
      return {
        label: user.full_name,
      };
    });

  return (
    <>
      <span
        style={{
          position: "absolute",
          right: "50px",
          top: "205px",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          style={{ border: "none", padding: "0px" }}
        >
          <PersonAddIcon style={{ padding: "0px" }} />
        </Button>
        <TextField
          id="outlined-basic"
          label="Search User"
          variant="outlined"
          onChange={handleChange}
        />
      </span>
      <Dialog open={open} onClose={handleClose} style={{ height: "500px" }}>
        <DialogTitle>Request User</DialogTitle>
        <br />
        <DialogContent>
          <DialogContentText>
            <Autocomplete
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              disablePortal
              sx={{ width: 300 }}
              options={usersAvailableToAdd}
              noOptionsText="No User by that name"
              renderInput={(params) => (
                <TextField {...params} label="Search Users" />
              )}
            />
            <br />
            <Button variant="contained" onClick={handleUserAdd}>
              Add User to {service.service_name}?
            </Button>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Invite User"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Send</Button>
        </DialogActions>
      </Dialog>

      {isSuccess &&
        data.map(({ full_name, username, role_name }, index) => (
          <TableRow key={index}>
            <TableCell align="left">{full_name}</TableCell>
            <TableCell align="left">{username}</TableCell>
            <TableCell align="left">
              <ButtonGroup
                size="small"
                sx={{
                  width: "150px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "align-items-center",
                  alignItems: "center",
                }}
                aria-label="small outlined button group"
              >
                <Button variant="outlined" onClick={handClickUserInfo}>
                  <span style={{ fontSize: "0px", textTransform: "lowercase" }}>
                    {username}
                  </span>
                  Role:&nbsp;<span id="role_name">{role_name}</span>
                  <ArrowDropDownIcon />
                </Button>
                <Button variant="contained" onClick={handleUserRemove}>
                  <span style={{ fontSize: "0px", textTransform: "lowercase" }}>
                    {username}
                  </span>
                  <PersonRemoveIcon style={{ pointerEvents: "none" }} />
                </Button>
              </ButtonGroup>
              <Menu
                anchorEl={anchorEl}
                open={openTrue}
                onClose={handleCloseTrue}
                variant="menu"
              >
                <MenuItem sx={{ width: 135 }} onClick={handleUserUpdate}>
                  ADMIN
                </MenuItem>
                <MenuItem onClick={handleUserUpdate}>EXEC</MenuItem>
                <MenuItem onClick={handleUserUpdate}>VIEW</MenuItem>
              </Menu>
            </TableCell>
          </TableRow>
        ))}
      {isLoading && <span>Loading..</span>}
      {error && <span>Error!</span>}
      {isSuccess &&
        isSuccessNotServices &&
        !data.length &&
        console.log("No Users in Service")}
    </>
  );
};

export default Service;
