import { PropTypes } from "prop-types";

import { Stack, Divider, Modal, Typography } from "@mui/material";
import DropDownCard from "./DropDownCard";
import React from "react";
import UIBreadcrumbs from "../../components/UIBreadcrumbs";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

const MoreDataCard = (props) => {
  return (
    <Modal
      open={props.openDetail}
      onClose={() => props.handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style}>
        <Stack direction="row" style={{ paddingBottom: "10px" }}>
          <UIBreadcrumbs></UIBreadcrumbs>
          <ChevronRightIcon
            fontSize="small"
            style={{
              color: "#9b51e0",
              marginTop: "4px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          ></ChevronRightIcon>
          <Typography color="secondary" sx={{ fontSize: 18 }}>
            {props.tableData && props.tableData["inventory_code"] ? props.tableData["inventory_code"]:""}
          </Typography>
        </Stack>
        <Divider style={{ width: "100%" }}></Divider>

        <Stack style={{ overflow: "auto", paddingTop: "8px" }}>
          {(props.tableData && props.tableData.length !==0) &&
            Object.keys(props.tableData)
              .map((row, index) => (
                <DropDownCard
                  header={row}
                  data={props.tableData[row]}
                  key={index}
                  isExpanded={false}
                ></DropDownCard>
              ))}
        </Stack>
      </Stack>
    </Modal>
  );
};

MoreDataCard.propTypes = {
  openDetail: PropTypes.bool,
  tableData: PropTypes.object,
  handleClose: PropTypes.func,
};

export default MoreDataCard;
