import "@fontsource/roboto/400.css";
import { GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./components/App";
import store from "./Store";
import theme from "./theme";
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Router>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            body: { backgroundColor: "#f2f5f8" },
          }}
        />
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  </Router>
);
