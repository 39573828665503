import { Auth } from "aws-amplify";
import axios from "axios";
import * as qs from 'qs'

const BI_API_BASE_URL = "https://fastapi.sampled-integration.com";

class BiService {

  async getFile(file_name) {
    let myInit = {
      params: {
        file_name: `${file_name}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/get-file", myInit);
  }

  async getDownloads(client, project) {
    let myInit = {
      params: {
        client: `${client}`,
        projects: project,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/downloads", myInit);
  }

  async doExport(profile) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    // let body = {};

    return await axios.post(
      BI_API_BASE_URL + "/export/data",
      profile,
      myInit
    );
  }

  async saveProfile(profile) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = profile;

    return await axios.post(
      BI_API_BASE_URL + "/export/user/save-profile",
      body,
      myInit
    );
  }

  async getProfiles(clientName) {
    let myInit = {
      params: {
        // query: `${clientName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/user/profiles", myInit);
  }

  async getFilters(clientName) {
    let myInit = {
      params: {
        // query: `${clientName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/filters", myInit);
  }

  async getFieldHeaders(client, project) {
    let myInit = {
      params: {
        client: `${client}`,
        projects: project,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/fields", myInit);
  }

  async getAllClients(clientName) {
    let myInit = {
      params: {
        query: `${clientName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/search/clients", myInit);
  }

  async getClientProjects(clientName, project) {
    let myInit = {
      params: {
        client: `${clientName}`,
        query: `${project}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/search/projects", myInit);
  }

  async advancedSearch(filterObj) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = filterObj;

    return await axios.post(
      BI_API_BASE_URL + "/sample/advanced-file-search",
      body,
      myInit
    );
  }

  async uploadFile(file) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = file;

    return await axios.post(
      BI_API_BASE_URL + "/sample/bulk-search/file/inventory-code",
      body,
      myInit
    );
  }

  async exportSearch(list) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = list;

    return await axios.post(
      BI_API_BASE_URL + "/sample/export/inventory-code",
      body,
      myInit
    );
  }

  async bulkSearch(list) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = list;

    return await axios.post(
      BI_API_BASE_URL + "/sample/bulk-search/inventory-code",
      body,
      myInit
    );
  }

  async getSingleCode(id) {
    let myInit = {
      params: {
        inventory_code: `${id}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/sample/search/inventory-code",
      myInit
    );
  }

  async getSingleMoreData(id) {
    let myInit = {
      params: {
        inventory_code: `${id}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/sample/detailed/inventory-code",
      myInit
    );
  }

  async getCodeList(id) {
    let myInit = {
      params: {
        query: `${id}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/sample/list/inventory-code",
      myInit
    );
  }

  async mapManifestPreview(file, mapping) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("mapping", JSON.stringify(mapping));

    return await axios.post(
      BI_API_BASE_URL + "/manifest/map/preview/table",
      formData,
      myInit
    );
  }

  async mapManifestExport(file, mapping) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("mapping", JSON.stringify(mapping));

    return await axios.post(
      BI_API_BASE_URL + "/manifest/map/preview/file",
      formData,
      myInit
    );
  }

  async getAllServices() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/navigation/service/directory",
      myInit
    );
  }

  async searchServices(query) {
    let myInit = {
      params: {
        query,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/navigation/service/search",
      myInit
    );
  }

  async getSidebar(route) {
    let myInit = {
      params: {
        route,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/navigation/service/sidebar",
      myInit
    );
  }

  async checkAccess(service_id) {
    let myInit = {
      params: {
        service_id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      BI_API_BASE_URL + "/navigation/service/access-level",
      myInit
    );
  }

  async searchProjects(client, query) {
    let myInit = {
      params: {
        client,
        query,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/export/search/projects", myInit);
  }

  async newManifestProfile(client, project, name, mapping) {
    let body = {
      client: client,
      project: project,
      name: name,
      mapping: JSON.stringify(mapping),
    };
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.post(
      BI_API_BASE_URL + "/manifest/new/profile",
      body,
      myInit
    );
  }

  async getManifestProfiles(client, project) {
    let myInit = {
      params: {
        client,
        project,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(BI_API_BASE_URL + "/manifest/profiles", myInit);
  }

  async ingestManifestFile(file, client, project, profile) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("client", client);
    formData.append("project", project);
    formData.append("profile", profile);

    return await axios.post(
      BI_API_BASE_URL + "/accession/accession-samples/file",
      formData,
      myInit
    );
  }
}

export default new BiService();
